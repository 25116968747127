<template>
  <a-tabs v-model:activeKey="activeKey">
    <a-tab-pane key="1" tab="机构评价">
      <table-page :config="config1" />
    </a-tab-pane>
    <a-tab-pane key="2" tab="文章评论">
      <table-page :config="config2" />
    </a-tab-pane>
  </a-tabs>
</template>

<script>
import { newCity, schoolNature, stayType, grade, curriculumSystems, country, countryAll } from '@/utils/public';
import TablePage from "@/components/TablePage";
export default {
  name: 'comment',
  components: { TablePage },
  props: ['selectedRows'],
  data() {
    return {
      activeKey: '1',
      config1: {
        dataUrl: '/clientUser/myFeedBack/getCommentList',
        filter: {
          // initUrl: '/system/employee/list/get',
          controls: [
            {
              key: 'uidOrNickname',
              label: 'UID/昵称',
              type: 'text',
            },
            {
              key: 'organizationName',
              label: '评价机构',
              type: 'text',
            },
            {
              key: 'time',
              label: '评价时间',
              type: 'dateRange'
            }
          ]
        },

        actions: [
        ],

        columns: [

          {
            key: 'uid',
            width: '100px',
            title: 'UID'
          },
          {
            key: 'nickname',
            width: '100px',
            title: '昵称'
          },
          {
            key: 'name',
            width: '100px',
            title: '客户名称'
          },
          {
            key: 'organizationName',
            width: '100px',
            title: '评价机构'
          },
          {
            key: 'subjectFamiliar',
            width: '100px',
            title: '专业度'
          },
          {
            key: 'applySpeed',
            width: '100px',
            title: '响应速度'
          },
          {
            key: 'serviceAttitude',
            width: '100px',
            title: '服务态度'
          },
          {
            key: 'averageScore',
            width: '100px',
            title: '平均分',
            type: 'textCustomCol',
            config: {
              text: (val) => {
                return ((parseFloat(val.subjectFamiliar) + parseFloat(val.applySpeed) + parseFloat(val.serviceAttitude)) / 3).toFixed(2)
              }
            }
          },
          {
            key: 'content',
            title: '评价内容',
            width: '200px',
            ellipsis: true
          },
          {
            key: 'createdDate',
            title: '评价时间',
            type: 'datetime',
            width: '200px',
          },
          {
            key: 'name',
            title: '操作',
            fixed: 'right',
            type: 'actions',
            width: document.documentElement.clientWidth <= 768 ? '70px' : '80px'
          }
        ],
        /* 表格排序 */
        sorter: {
          /* 默认排序 */
          defaultSort: ''
        },
        /* 表格行操作 */
        rowActions: [
          {
            key: 'lock',
            text: '删除',
            type: 'confirm',
            permission: '/clientUser/myFeedBack/delete',
            disabled: ({ selectedRows }) => {
              return true;
            },
            config: {
              color: 'danger',
              submitColor: 'danger',
              submitUrl: '/clientUser/myFeedBack/delete'
            }
          },
        ]
      },
      config2: {
        dataUrl: '/article/comment/list',
        filter: {
          // initUrl: '/system/employee/list/get',
          controls: [
            {
              key: 'nicknameOrUid',
              label: 'UID/昵称',
              type: 'text',
            },
            {
              key: 'articleTitle',
              label: '评价文章',
              type: 'text',
            }
          ]
        },
        actions: [
        ],
        columns: [

          {
            key: 'uid',
            width: '100px',
            title: 'UID'
          },
          {
            key: 'nickname',
            width: '100px',
            title: '昵称'
          },
          {
            key: 'articleTitle',
            title: '文章标题',
            width: '200px',
            ellipsis: true
          },
          {
            key: 'relateOrg',
            width: '100px',
            title: '相关机构'
          },
          {
            key: 'relateSchool',
            width: '100px',
            title: '相关学校'
          },
          {
            key: 'content',
            title: '评价内容',
            width: '200px',
            ellipsis: true
          },
          {
            key: 'platform',
            width: '100px',
            title: '评论平台'
          },
          {
            key: 'name',
            title: '操作',
            fixed: 'right',
            type: 'actions',
            width: document.documentElement.clientWidth <= 768 ? '70px' : '80px'
          }
        ],
        /* 表格排序 */
        sorter: {
          /* 默认排序 */
          defaultSort: ''
        },
        /* 表格行操作 */
        rowActions: [
          {
            key: 'lock',
            text: '删除',
            type: 'confirm',
            permission: '/article/comment/delete',
            disabled: ({ selectedRows }) => {
              return true;
            },
            config: {
              color: 'danger',
              submitColor: 'danger',
              submitUrl: '/article/comment/delete'
            }
          },
        ]
      }
    };
  },
  mounted() {
  }

};
</script>

<style lang="less" scoped></style>